import '@styles/main.scss';
import $ from 'jquery';
import EventSwiper from './components/event-swiper';
import Swiper, {Navigation} from 'swiper';
import 'swiper/css';
import CaseStudySwiper from './components/case-study-swiper';

class App {

    constructor() {

        jQuery('#toggle').on("click", function() {
            jQuery(this).toggleClass('active');
            jQuery('#overlay').toggleClass('open');
        });

        new EventSwiper();
        new CaseStudySwiper();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
